.panel::-webkit-scrollbar {
  width: 14px;
}

/* Track */
.panel::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 2px rgba(0, 0, 0, 0.3);
}

/* Handle */
.panel::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
}
.panel::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.1);
}
