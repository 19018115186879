html,
body,
#root {
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}

.monaco-editor .scroll-decoration {
  box-shadow: #000000 0 4px 6px -6px inset !important;
}

/*
.monaco-editor .decorationsOverviewRuler {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 2px rgba(0, 0, 0, 0.3);
}
*/
